import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Logo from "./logo";
import Navlinks from "./navlinks";
import styled from "styled-components";
import "../style/footer.less";

const Footer = function ({ className }: { className?: string }) {
  const query = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <footer className={"footer " + className}>
      <div className="container">
        <div className="logo">
          <Link to="/" title={query.site.siteMetadata.title}>
            <Logo />
          </Link>
        </div>
        <div className="navlinks text-secondary">
          <Navlinks />
        </div>
        <p className="text-primary f-d">
          &copy; {new Date().getFullYear()} {query.site.siteMetadata.title}
        </p>
      </div>
    </footer>
  );
};

export default styled(Footer)`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
